var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật mẫu lời chúc",
        visible: _vm.dialogVisible,
        width: "50%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "10vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "newWishesSample",
          attrs: {
            "label-width": "120px",
            model: _vm.newWishesSample,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Đối tượng", prop: "receiverType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.newWishesSample.receiverType,
                    callback: function ($$v) {
                      _vm.$set(_vm.newWishesSample, "receiverType", $$v)
                    },
                    expression: "newWishesSample.receiverType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Học sinh" } }, [
                    _vm._v("Học sinh"),
                  ]),
                  _c("el-radio", { attrs: { label: "Phụ huynh" } }, [
                    _vm._v("Phụ huynh"),
                  ]),
                  _c("el-radio", { attrs: { label: "Giáo viên" } }, [
                    _vm._v("Giáo viên"),
                  ]),
                  _c("el-radio", { attrs: { label: "Nhà trường" } }, [
                    _vm._v("Nhà trường"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Nội dung", prop: "wishesContent" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 5,
                  type: "textarea",
                  placeholder: "Nhập nội dung",
                },
                model: {
                  value: _vm.newWishesSample.wishesContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.newWishesSample, "wishesContent", $$v)
                  },
                  expression: "newWishesSample.wishesContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Chọn ảnh" } },
            [
              _vm.newWishesSample.urlPicture != null && _vm.showPicutreInitial
                ? _c("el-image", {
                    staticStyle: {
                      width: "150px",
                      height: "150px",
                      float: "left",
                      "margin-right": "20px",
                    },
                    attrs: { src: _vm.newWishesSample.urlPicture, fit: "fill" },
                  })
                : _vm._e(),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "avatar-uploader",
                  class: { hideUpload: !_vm.showUpload },
                  attrs: {
                    "show-file-list": true,
                    "list-type": "picture-card",
                    "on-change": _vm.handleChangeUpload,
                    "on-remove": _vm.handleRemove,
                    "auto-upload": false,
                    limit: "1",
                  },
                },
                [
                  _vm.newWishesSample.urlPicture != null
                    ? _c("span", [
                        _c(
                          "i",
                          { staticClass: "el-icon-plus avatar-uploader-icon" },
                          [_vm._v("Cập nhật ảnh")]
                        ),
                      ])
                    : _c("span", [
                        _c(
                          "i",
                          { staticClass: "el-icon-plus avatar-uploader-icon" },
                          [_vm._v("Thêm ảnh")]
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("newWishesSample")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("newWishesSample")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }